import { Fragment } from "react";
import { Home } from "lucide-react";
import { carrierAssureBackground } from "~/assets/images";
import { socialNetworks } from "~/utils/social-networks";
import { Link, Outlet, useLocation } from "@remix-run/react";

const AuthLayout = () => {
  const { pathname } = useLocation();

  return (
    <Fragment>
      <div className="fixed inset-0 -z-10 pt-24">
        <img
          src={carrierAssureBackground}
          alt="Carrier Assure logo with initials"
          className="h-full max-h-[30rem] w-full object-cover object-top sm:max-h-full"
          width={1348}
          height={806}
        />
      </div>
      <main className="px-6">
        {pathname.includes("/sign-in") || pathname.includes("/sign-up") ? (
          <Link
            to="https://www.carrierassure.com"
            className="flex w-fit items-center gap-2 pl-1 pt-5"
          >
            <Home className="h-6 w-6 text-white" />
            <h3 className="pt-1">Go to Home</h3>
          </Link>
        ) : null}
        <Outlet />
      </main>
      <footer className="flex justify-center gap-5 py-12 sm:fixed sm:bottom-0 sm:right-0 sm:flex-col sm:p-5 md:p-9">
        {socialNetworks.map((socialNetwork) => (
          <a key={socialNetwork.label} href={socialNetwork.href}>
            <socialNetwork.icon
              role="img"
              aria-label={socialNetwork.label}
              className="h-5 w-5 text-gray-500 transition-colors hover:text-gray-400 sm:text-red-50 sm:hover:text-white"
            />
          </a>
        ))}
      </footer>
    </Fragment>
  );
};

export default AuthLayout;
